import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Wisteria Restaurant",
  pageDescription:
    "Learn how a casual-dining restaurant uses SpotOn technology to stay up to date with changing times all while maintaining its elevated Southern style.",
  title: "Cooking up real Southern food with quick, seamless ordering",
  subTitle: `How Wisteria uses SpotOn to keep up with changing times.`,
  businessName: "Wisteria Restaurant",
  industry: "Restaurant, casual dining",
  location: "Georgia, Atlanta",
  products: [spotonProducts.RESTAURANT, spotonProducts.ORDER],
  description:
    "Wisteria offers southern style cuisine and a crafted experience for new and old guests alike. Thanks to SpotOn technology, chef and owner Jason Hill is able to quicken the ordering process so visitors don't have to wait long for delicious food.",
  quoteData: {
    imgName: "wisteria.png",
    quote: `“With SpotOn, our staff doesn't spend time putting in orders. It’s simple to use. They have more time to spend with guests, getting food out, making sure everyone's getting everything as quickly as possible.”`,
    personName: "Christian Collins",
    personTitle: "General Manager, Wisteria",
  },
  goal: `Chef Jason Hill wanted to stay true to Wisteria’s southern cuisine all while keeping his staff and guests at ease. But one of the biggest challenges to Wisteria’s authenticity came during the pandemic when Hill was pressured to tweak menus and adjust dining capacities.`,
  solution: `With SpotOn Restaurant, Hill could prioritize staff comfort while speeding up guest ordering times. Hill even used SpotOn reporting to get an accurate read on what is and isn’t selling. And since SpotOn Order made online operations such an easy process, Hill could focus on cooking up an authentic Southern experience.`,
  solutionReadMore: {
    text: `Read their story.`,
    url: `https://www.spoton.com/blog/redefining-wisteria/`,
  },
  results: {
    title: `The results`,
    stats: [
      {
        title: `3x`,
        description: `more off-premise orders with SpotOn`,
      },
      {
        title: `1 hour`,
        description: `saved on operations per week`,
      },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Managing 3 popular Japanese restaurants",
    imageName: "momoya-with-logo.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-07-22",
    href: "/case-studies/momoya",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
    ],
  },
  {
    title: "A southern restaurant sees profits go north",
    imageName: "southern-table.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-02-28",
    href: "/case-studies/southern-table",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
    ],
  },
  {
    title: "Boosting traffic to a BBQ business",
    imageName: "chicago-culinary-kitchen.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-05-02",
    href: "/case-studies/chicago-culinary-kitchen",
    goals: [
      resourceGoals.REACH_NEW_CUSTOMERS,
      resourceGoals.BUILD_CUSTOMER_LOYALTY,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
];
